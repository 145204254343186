var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-payment-gatways')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"email",staticClass:"row",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
                },attrs:{"id":"input-1","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.product.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-multi"}},[_vm._v("Payment Gateway Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                  'is-invalid':
                    _vm.submitted && _vm.$v.form.payment_gateway_type_id.$error,
                },attrs:{"options":_vm.payment_gateway_type,"value-field":"id","text-field":"name","placeholder":"select option"},model:{value:(_vm.form.payment_gateway_type_id),callback:function ($$v) {_vm.$set(_vm.form, "payment_gateway_type_id", $$v)},expression:"form.payment_gateway_type_id"}}),(_vm.submitted && !_vm.$v.form.product.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" payment gateway type is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("slug "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.slug.$error,
                },attrs:{"id":"input-1","placeholder":"Enter slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),(_vm.submitted && !_vm.$v.form.product.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" slug is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("config "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.config.$error,
                },attrs:{"id":"input-1","placeholder":"Enter config"},model:{value:(_vm.form.config),callback:function ($$v) {_vm.$set(_vm.form, "config", $$v)},expression:"form.config"}}),(_vm.submitted && !_vm.$v.form.product.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" config is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{staticStyle:{"font-weight":"500"},attrs:{"for":"multi_event"}},[_vm._v("Product "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('multiselect',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.product.$error,
                },attrs:{"id":"item","options":_vm.getProduct,"multiple":true,"track-by":"id","label":"name","placeholder":"Type here to search"},model:{value:(_vm.form.product),callback:function ($$v) {_vm.$set(_vm.form, "product", $$v)},expression:"form.product"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.product.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" product is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ckeditor',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
                },attrs:{"editor":_vm.editor},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.product.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('b-form-checkbox',{on:{"input":_vm.changestatus},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v("is active")])],1),_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-payment-gatways')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }